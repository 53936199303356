<div class="row minicart-comp">
  <div class="component col-12 mb-2 CartSelector">
    <div *ngIf="cartsList$ | async as cartsList; else cartSession" class="position-relative">
      <div class="current-cart-wrapper">
        <div class="current-cart-btn" (click)="navigateToCart()">
          <div *ngIf="cartsList?.carts?.length > 0; else nocart">
            <div>
              {{cartsList?.carts?.at(0)?.name ? cartsList?.carts[0].name : ("homePageHeader.newCart" | cxTranslate)}}
            </div>
            <div>
              {{cartsList?.carts?.at(0)?.totalItems === 0
              ? ("homePageHeader.emptyCart" | cxTranslate)
              : (cartsList?.carts[0].totalItems+" "+("homePageHeader.itemsInCart" | cxTranslate))
              }}
            </div>
          </div>
          <ng-template #nocart>
            <div>{{ "homePageHeader.newCart" | cxTranslate }}</div>
            <div>{{ "homePageHeader.emptyCart" | cxTranslate }}</div>
          </ng-template>
        </div>
        <div class="current-cart-toggle-dropdown d-flex justify-content-center align-items-center" (click)="toggle()">
          <i [ngClass]="toggleCarts ? 'pi pi-chevron-up' : 'pi pi-chevron-down'" style="font-size: 1.3em"></i>
        </div>
      </div>
      <div *ngIf="toggleCarts" class="switch-cart-dropdown">
        <ng-container *ngFor="let cart of cartsList?.carts?.slice(1, 5); let i = index">
          <div class="switch-cart-item" (click)="changeCart(i)">
            <div class="d-flex">
              <div class="item-icon">
                <i class="fa fa-shopping-cart"></i>
              </div>
              <div class="item-text">
                <div>
                  {{cart.name ? cart.name : ("homePageHeader.newCart" | cxTranslate)}}
                </div>
                <div>
                  {{
                  cart?.totalItems === 0
                  ? ("homePageHeader.emptyCart" | cxTranslate)
                  : (cart?.totalItems+" "+("homePageHeader.itemsInCart" | cxTranslate))
                  }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="switch-cart-controls">
          <div class="switch-cart-item" (click)="newCartClick()">
            <div class="d-flex">
              <div class="item-icon">
                <i class="fa fa-cart-plus"></i>
              </div>
              <div class="item-text">
                <div>{{ "homePageHeader.newCart" | cxTranslate }}</div>
              </div>
            </div>
          </div>
          <div class="switch-cart-item" (click)="navigateToManageCarts()">
            <div class="d-flex">
              <div class="item-icon">
                <i class="fa fa-ellipsis-h"></i>
              </div>
              <div class="item-text">
                <div>{{ "homePageHeader.manageCarts" | cxTranslate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #cartSession>
      <div class="position-relative">
        <div class="current-cart-wrapper">
          <div class="current-cart-btn custom-flex-center">
            <span class="mr-2">{{"homePageHeader.loading" | cxTranslate}}</span>
            <i class="pi pi-spin pi-spinner mini-cart-spinner"></i>
          </div>
          <div class="current-cart-toggle-dropdown d-flex justify-content-center align-items-center">
            <i class="pi pi-chevron-down" style="font-size: 1.3em"></i>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!-- Create New Cart Modal -->
<div #cartModal class="modal fade" tabindex="-1" id="cartModal" aria-labelledby="cartModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content" style="color: black">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <h4 class="m-0">{{ "homePageHeader.newCart" | cxTranslate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ "homePageHeader.creatingCart" | cxTranslate }}</p>
        <form class="col-lg-12 p-0" [formGroup]="saveCartForm">
          <div class="form-row">
            <div class="form-group col-lg-12">
              <label class="request-label required" for="cartName">{{
                "homePageHeader.cartName" | cxTranslate
                }}</label>
              <input type="text" formControlName="cartName" class="form-control request-data" />
            </div>
            <div class="form-group col-lg-12">
              <label class="request-label required" for="legalEntity">{{
                "homePageHeader.legalEntities" | cxTranslate
                }}</label>
                <p-dropdown [options]="legalEntityList" [styleClass]="(legalEntity?.invalid && legalEntity?.touched) ? 'border-red-500 cstm-dropdown' : 'cstm-dropdown'"
                    formControlName="legalEntity" [filter]="true">
                </p-dropdown>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer d-flex justify-content-start mb-2">
        <button type="button" class="btn cstm-btn-light mb-0 mr-3" data-dismiss="modal">
          {{ "homePageHeader.cancel" | cxTranslate }}
        </button>
        <button type="button" class="btn cstm-btn-primary mb-0" (click)="onNewCartClick()">
          {{ "homePageHeader.create" | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>