import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { environment } from '../../../../../environments/environment';
import { CmsService } from '@spartacus/core';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-faq-order-video-popup',
  templateUrl: './faq-order-video-popup.component.html',
  styleUrl: './faq-order-video-popup.component.scss'
})
export class FaqOrderVideoPopupComponent {

  public componentData: any;
  videoUrl: string;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public component: CmsComponentData<any>,
    private pageService: CmsService
  ) { }

  ngOnInit(): void {
    this.pageService.getComponentData('OrdersQ1VideoComponent')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        this.videoUrl = environment.siteUrls.getBackofficeUrl + data?.video?.url.slice('/medias');
      });
    this.component.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.componentData = data;
      });
  }

  stopVideo(video) {
    video.pause();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
