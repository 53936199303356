<div class="row">
  <div class="component col-12 CustomCarousel">
    <div class="carousel col initialized">
      <div class="row">
        <img src="{{listImg[i]}}" class="d-block carousel-item-image mw-100" style="max-height: 500px; width: 100%;">
      </div>
      <div data-v-2dcb6341="" class="row homepage-carousel-navigation-container">
        <div data-v-2dcb6341="" class="col center-align-col">
          <a data-v-2dcb6341="" role="button" class="nav-btn-link">
            <i class="sg-icon sg-icon-arrow arrow-left" (click)="prevSlide()"></i>
          </a>
          <span class="carousel-number-active" data-v-2dcb6341="">{{i + 1 | number:'2.0'}}</span>
          <span class="carousel-number-total">{{lengthArray | number:'2.0'}}</span>
          <a role="button" class="nav-btn-link">
            <i class="sg-icon sg-icon-arrow " (click)="nextSlide()"></i>
          </a>
        </div>
      </div>
    </div>
  </div>