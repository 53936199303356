import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { EndSessionPopupRequest, EndSessionRequest, PublicClientApplication } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { AsmAuthStorageService } from '@spartacus/asm/root';
import {
  AuthMultisiteIsolationService,
  AuthRedirectService,
  AuthService,
  GlobalMessageService,
  OAuthLibWrapperService,
  RoutingService,
  StateWithClientAuth,
  UserIdService,
} from '@spartacus/core';
import { msalConfig } from '../../../SGRE-config/auth-config';
import { StorageService } from '../storage.service';
import { environment } from '../../../../environments/environment';

/**
 * Version of AuthService that is working for both user na CS agent.
 * Overrides AuthService when ASM module is enabled.
 */
@Injectable({
  providedIn: 'root',
})
export class MSALSpaAuthService extends AuthService {

  public static msalInstance = new PublicClientApplication(msalConfig);
  public isIframe = window !== window.parent && !window.opener;
  public static channel = new BroadcastChannel("SGRE_SAP_CS_Channel");

  constructor(
    protected storageService: StorageService,
    protected msalService: MsalService,
    protected override store: Store<StateWithClientAuth>,
    protected override userIdService: UserIdService,
    protected override oAuthLibWrapperService: OAuthLibWrapperService,
    protected override authStorageService: AsmAuthStorageService,
    protected override authRedirectService: AuthRedirectService,
    protected globalMessageService: GlobalMessageService,
    protected override routingService: RoutingService,
    protected override authMultisiteIsolationService?: AuthMultisiteIsolationService,
  ) {
    super(
      store,
      userIdService,
      oAuthLibWrapperService,
      authStorageService,
      authRedirectService,
      routingService,
      authMultisiteIsolationService
    );
    // MSALSpaAuthService.msalInstance.initialize();
  }

  /**
   * Revokes tokens and clears state for logged user (tokens, userId).
   * To perform logout it is best to use `logout` method. Use this method with caution.
   */
  override coreLogout(): Promise<void> {
    return super.coreLogout().finally(() => {
      this.MSALlogout();
    })
  }

  MSALlogout() {
    MSALSpaAuthService.channel.postMessage("TRIGGER_LOGOUT");
    const currentAccount = this.msalService.instance.getAllAccounts()?.at(0);

    let session: EndSessionRequest | EndSessionPopupRequest = {
      account: currentAccount,
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
    };
    console.log('currentAccount:', currentAccount);
    console.log('postLogout redirectURL:', msalConfig.auth.postLogoutRedirectUri);

    if (this.isIframe) { session['mainWindowRedirectUri'] = environment.msalConfigDetails.redirectUrls.smartEdit }

    this.isIframe
      ? this.msalService.logoutPopup(session)
      : this.msalService.logoutRedirect(session);

    this.msalService.instance.clearCache();
    this.storageService.reset();
    this.storageService.resetSessionDetails();
  }
}