<div class="dropdown">
    <a class="nav-link dropdown-toggle custom-align-center user-icon-mobile">
        <!-- <img src="assets/images_and_icons/person.svg"> -->
        <!-- <i class="fa-regular fa-user"></i> -->
        <i class="sg-icon sg-icon-client user-icon"></i>
    </a>
    <div *ngIf="((login$ | async) === true || (login$ | async) === null); else logout"
        class="dropdown-menu m-0 p-0 login-options">
        <a routerLink="{{requestAccessUrl}}" class="dropdown-item">{{'homePageHeader.requestAccess' |cxTranslate}}</a>
        <a href="javascript:void(0)" class="dropdown-item" (click)="login()">{{'homePageHeader.signIn'
            |cxTranslate}}</a>
    </div>
    <ng-template #logout>
        <div class="dropdown-menu m-0 p-0 login-options">
            <a [routerLink]="myProfileUrl" class="dropdown-item">{{'homePageHeader.myProfle' |cxTranslate}}</a>
            <a href="javascript:void(0)" class="dropdown-item" (click)="logOut()">{{'homePageHeader.signOut'
                |cxTranslate}}</a>
        </div>
    </ng-template>
</div>