import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventType, EventMessage, AuthorizationCodeRequest } from '@azure/msal-browser';
import { MSALSpaAuthService } from '../../../services/login-sso/msal-auth.service';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, filter, fromEvent, takeUntil, tap } from 'rxjs';
import { BaseSiteService, AuthRedirectService } from '@spartacus/core';
import { GlobalService } from '../../../services/global.service';
import { AppConstants } from '../../../constants/app-constant';
import { SmartEditLauncherService } from '@spartacus/smartedit/root';

@Component({
  selector: 'app-header-sign-in',
  templateUrl: './header-sign-in.component.html',
  styleUrl: './header-sign-in.component.scss'
})
export class HeaderSignInComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();
  loader$ = new BehaviorSubject(false);
  login$: Observable<any>;
  requestAccessUrl = AppConstants.routeUrls.requestAccess;
  myProfileUrl = AppConstants.routeUrls.myProfile;

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    protected baseSiteService: BaseSiteService,
    protected authRedirectService: AuthRedirectService,
    private globalService: GlobalService,
    private msalAuthService: MSALSpaAuthService,
    private smartEditService: SmartEditLauncherService,
  ) {
    this.globalService.loginBannerSubject.next(this.globalService.showBanner());
  }

  ngOnInit(): void {
    combineLatest([
      this.baseSiteService.getActive(),
      this.msalBroadcastService.msalSubject$.pipe(
        tap((event: EventMessage) => console.log('MSAL_Event captured:', event)),
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        )
      ),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([baseSite, result]) => {
        this.globalService.checkAndUpdateMsalData(result);
      })

    this.login$ = this.globalService.loginBanner$;
    this.channelListener();
  }

  login() {
    this.globalService.checkSmartEdit()
      ? this.msalService.loginPopup()
      : this.msalService.loginRedirect();
  }

  logOut() {
    this.msalAuthService.coreLogout();
  }

  channelListener() {
    let index = 0;
    MSALSpaAuthService.channel.addEventListener("message", (event) => {
      if (event?.data === 'TRIGGER_LOGOUT' && this.globalService.isUserLoggedIn() && index === 0) {
        ++index;
        this.msalAuthService.coreLogout();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
