import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-faq-paragraph',
  templateUrl: './faq-paragraph.component.html',
  styleUrl: './faq-paragraph.component.scss'
})
export class FaqParagraphComponent {

  public componentData: any;
  private unsubscribe$ = new Subject<void>();

  constructor(public component: CmsComponentData<any>) { }
  ngOnInit(): void {
    this.component.data$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data) => {
      this.componentData = data;
    });
  }
  
  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
